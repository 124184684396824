.activité-container {
  display: flex;
}

.activité-container .contentBlock-container {
  width: 30%;
}
.activité-container {
  max-width: 1200px;
}

.projet-container {
  margin-bottom: 30px;
}

#name {
  margin-top: 20px;
}

.projet-container img:hover {
  width: 244px;
  cursor: pointer;
  transition: 180ms;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.5); /* Increased shadow values */
}

.pp-container {
  margin-bottom: 25px;
  width: 36%;
  text-align: center;
}
.projet-container .contentBlock-container {
  width: 50%;
}

#tarif-desktop {
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 40px;
}

.show-tarif-desktop {
  overflow: hidden; /* Hide overflow content */
  transition: max-height 1s ease-in-out; /* Add transition effect */
  max-height: 1000px; /* Set a large max-height for expansion */
}

.hidden-tarif-desktop {
  overflow: hidden; /* Hide overflow content */
  transition: max-height 1s ease-out; /* Add transition effect */
  max-height: 0px;
}
.info-prix {
  margin-top: 20px;
}
.tarif-title {
  margin-bottom: 20px;
}

.price-prestation {
  display: block;
  margin-bottom: 45px;
  text-align: left;
  justify-content: start;
  padding-left: 17px;
  align-self: flex-start;
}
#tarif-desktop .price-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 30%;
  padding-top: 0px;
}

#tarif-mobile .price-container {
  padding-top: 0px;
}

#display-price-btn {
  z-index: 0;
}

.description-list {
  margin-top: 10px;
}

.price-mobile {
  display: none;
}

.price-detail-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.présentation-perso-content {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.présentation-perso-content p {
  width: 58%;
}

#desktop-section-tarif {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-block #pp {
  width: 100%;
  height: auto;
  border-radius: 35%;
  filter: brightness(90%);
}
.content-block {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CTA-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
}
.content-block .title {
  max-width: 900px;
  font-size: 1.8em;
}

.intro-block {
  max-width: 900px;
}

#présentation-perso {
  max-width: 900px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .content-block h2,
  .intro-text {
    max-width: 700px;
  }

  .projet-container .contentBlock-content {
    gap: 0px;
  }

  .price-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  /* .price-prestation {
    margin-bottom: 35px;
  } */

  #desktop-section-tarif {
    display: none;
  }

  .hidden-tarif-mobile {
    display: none;
  }

  .price-btn-desktop {
    display: none;
  }

  .activité-container {
    flex-direction: column;
    align-items: center;
    max-width: 600px;
  }

  .activité-container .contentBlock-container {
    width: 80%;
    margin-bottom: 0%;
  }
}

@media screen and (max-width: 811px) {
  .content-block h2,
  .intro-text {
    max-width: 500px;
  }
  .pp-container {
    width: 100%;
  }

  .présentation-perso-content {
    flex-direction: column;
    align-items: center;
    max-width: 500px;
  }
  .présentation-perso-content #pp {
    width: 300px;
    margin-bottom: 25px;
  }
  .présentation-perso-content p {
    width: 100%;
  }
  .activité-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
