#contact-page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#date-container {
  margin-bottom: 0px;
}

#date-selector-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

#date-container .input-container {
  font-size: 0.85em;
  margin-bottom: 0px;
}

#contact-page-content #checkbox-date-container {
  font-size: 0.85em;
  margin-bottom: 0px;
}

#message {
  height: 160px;
  width: 100%;
}
#success-msg,
#error-msg {
  text-align: center;
  margin-top: 30px;
  max-width: 100%;
  align-self: center;
}
#checkbox-date-container label {
  text-align: center;
}

#contact-page-content form {
  display: flex;
  flex-direction: column;
  max-width: 280px;
}

#contact-page-content form .input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

#contact-page-content form .input-container label {
  margin-bottom: 7px;
}

#contact-page-content form button {
  margin-top: 25px;
}

.required-asterisk {
  color: rgba(255, 0, 0, 0.863);
}

#google-calendar-mobile {
  display: none;
}
#noDate {
  align-self: center;
}
@media screen and (max-width: 811px) {
  #google-calendar-desktop {
    display: none;
  }
  #google-calendar-mobile {
    display: block;
    width: 100%;
  }
}
