body {
  margin: 0;
  padding: 0;
  margin-block: 0px;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;
  background-color: rgb(0, 0, 0);
  background-position: center;
  width: 100vw;
  display: flex;
  justify-content: center;
  white-space: pre-line;
  vertical-align: bottom;
  color: white;
  min-width: 320px;
}

h1,
h2,
h3,
h4,
ul,
p {
  margin: 0;
  padding: 0;
  margin-block: 0px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.content-container {
  margin: 0% 1%;
  margin-top: 45px;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

main {
  display: block;
  background-color: rgb(0, 0, 0);
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
}

.page-img {
  width: 100vw;
  height: 500px;
  object-fit: cover;
  filter: brightness(85%);
  margin-top: 110px;
}

#img-fade {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 510px;
  z-index: 0;
  width: 100vw;
  height: 100px; /* Adjust the height of the fade as needed */
  background: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1.5)
  ); /* Adjust the opacity and colors as needed */
}

.block-separation {
  width: 80%;
  justify-content: center;
  height: 1px;
  background-color: rgba(71, 70, 70, 0.5);
  margin-top: 80px;
  margin-bottom: 50px;
}

.title {
  margin-bottom: 32px;
}
.underlined {
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .content-container {
    max-width: 850px;
    width: 80%;
  }

  .page-img {
    height: 370px;
    margin-top: 90px;
  }
  #img-fade {
    top: 370px;
  }
}

@media screen and (max-width: 811px) {
  .content-container {
    width: 80%;
    min-width: 250px;
  }
  .page-img {
    height: 300px;
    margin-top: 70px;
  }
  #img-fade {
    top: 270px;
  }

  .subtitle {
    display: flex;
    justify-content: center;
  }
}
