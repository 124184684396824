.nav-container {
  display: flex;
  gap: 30px;
  margin: 30px 50px;
  width: fit-content;
  border-radius: 45px;
}
.regular-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 0;
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 120px;
}

/* Sidenav menu */
.sidenav {
  display: none;
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 90px;
  right: -200px;
  background-color: #000000;
  padding-top: 10px;
  transition: right 0.5s ease;
}

/* Sidenav menu links */
.sidenav a {
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  font-size: 25px;
  color: #000000;
  display: block;
  transition: 0.3s;
  width: 100%;
}

.sidenav a:hover {
  background-color: #1f1e1e;
}

.sidenav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Active class */
.sidenav.active {
  right: 0;
}

/* Close btn */
.sidenav .close {
  position: absolute;
  top: 0;
  left: 0px;
  font-size: 36px;
}

/* Icône burger */
.burger-icon span {
  display: block;
  width: 35px;
  height: 5px;
  background-color: #208be2;
  margin: 6px 0;
  border-radius: 2px;
}

.burger-icon {
  cursor: pointer;
  display: none;
  position: fixed;
  top: 25px;
  right: 25px;
  z-index: 2;
}

@media screen and (max-width: 1024px) {
  #backBtn-container {
    display: none;
  }

  .sidenav {
    display: block;
  }

  .burger-icon {
    display: block;
  }
  nav {
    justify-content: end;
  }

  .regular-nav {
    display: none;
  }
}

@media screen and (max-width: 811px) {
  .burger-icon {
    top: 17px;
    right: 25px;
  }

  .burger-icon span {
    width: 35px;
    height: 4px;
  }

  .sidenav {
    top: 70px;
  }
}
