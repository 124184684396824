.CTA-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
}

.CTA-block .title {
  margin-bottom: 50px;
}

@media screen and (max-width: 811px) {
  .CTA-block .title {
    font-size: 1.4em;
    margin-bottom: 35px;
  }
}
