header {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 5em;
  margin-bottom: 30px;
  position: fixed;
  top: 0px;
  background-color: #000000;
  width: 100vw;
  z-index: 1;
  border-bottom: 1px solid rgba(41, 40, 40, 0.562);
}

header #header-title {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  header {
    justify-content: start;
    padding-left: 20px;
    text-align: start;
    font-size: 3em;
    height: 90px;
  }
}

@media screen and (max-width: 811px) {
  header {
    height: 70px;
    font-size: 1.3em;
  }
}
