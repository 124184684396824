.matos-category {
  margin: 15px 0px 10px 0px;
  align-self: flex-start;
}

.matos-element {
  margin-bottom: 4px;
  align-self: flex-start;
}

.matos {
  max-width: 700px;
}

.content-block {
  width: 100%;
}

#matos-container {
  width: 80%;
}

.content-block-container {
  display: flex;
  gap: 85px;
}

.content-block {
  justify-content: inherit;
}

@media screen and (max-width: 1024px) {
  .content-block-container {
    flex-direction: column;
    gap: 5px;
  }
  .matos-element {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 811px) {
  .matos-element {
    margin-bottom: 6px;
  }
}
