footer {
  bottom: 0;
  margin-top: 100px;
  height: 200px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  width: 100vw;
  background-color: black;
  display: flex;
  border-top: 1px solid rgba(129, 127, 127, 0.384);
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

footer .socials-container {
  display: flex;
  gap: 7px;
}

footer svg {
  height: 50px;
  width: auto;
}
footer path {
  fill: #208be2;
}
footer a {
  color: white;
  text-decoration: inherit;
  margin-top: 20px;
}
