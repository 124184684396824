.contentBlock-container {
  color: white;
  padding: 35px 30px;
  border-radius: 45px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 0px 30px;
}

.contentBlock-title-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.contentBlock-children {
  justify-content: center;

  display: flex;
}

.contentBlock-children .contentBlock-container {
  width: 30%;
}

#présentation
  .contentBlock-children
  .contentBlock-container {
  width: auto;
}

.contentBlock-level-1 {
  background-color: #000000;
}

.contentBlock-level-2 {
  background-color: #383434;
}

.contentBlock- {
  text-align: center;
  margin-bottom: 25px;
}

.contentBlock-content ul {
  margin: 5px 20px;
  list-style: disc;
}

.contentBlock-content li {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-bottom: 10px;
}

.contentBlock-content {
  justify-content: center;
  align-items: center;
  gap: 50px;
  display: flex;
  flex-direction: column;
}

.contentBlock-title-introText {
  margin: 0px 30px;
  margin-bottom: 20px;
}

.subtitle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.content-img {
  width: 235px;
  height: auto;
  transition: 180ms;
}

.contentBlock-icon {
  width: auto;
  height: 50px;
  margin-bottom: 15px;
}

@media screen and (max-width: 1024px) {
  .contentBlock-children {
    display: flex;
    flex-direction: column;
  }

  .contentBlock-children .contentBlock-container {
    width: auto;
  }
  .contentBlock-container {
    margin: 20px 0px;
  }
}

@media screen and (max-width: 811px) {
  .contentBlock-container {
    border: none;
  }
}
