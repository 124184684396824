.btn-container {
  background-color: #208be2;
  padding: 12px 24px;
  box-shadow: 0px 4px 4px 0px rgba(37, 37, 37, 0.5);
  border-radius: 20px;
  text-decoration: none;
}

.btn-container .btn-container-txt {
  font-weight: bold;
  color: white; /* Transition for the filter property */
}

.btn-container:hover {
  filter: brightness(110%);
  transition: filter 0.25s ease;
}

@media screen and (max-width: 1024px) {
  .btn-container .btn-container-txt {
    display: flex;
    justify-content: center;
    font-size: 0.9em;
  }

  .btn-container {
    border-radius: 0px;
    background-color: black;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
  }
}

@media screen and (max-width: 811px) {
  .btn-container {
    width: 70%;
  }

  .btn-container .btn-container-txt {
    font-size: 0.8em;
  }
}
