#projets-container {
  max-width: 900px;
}

#projets-container #projets-title {
  margin-bottom: 0px;
}
.subtitle {
  margin-bottom: 15px;
  margin-top: 30px;
}
.projets-list {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}

.projets-list a {
  margin-bottom: 10px;
  text-decoration: inherit;
  color: white;
}

.category-container {
  width: 100%;
}
